export type API_VideoReference = {
  ytid: string;
  title: string;
  thumbnailUrl: string;
  slug: string;
  publishDate: string;
  hasScript: boolean;
};

export type API_Playlist = {
  videos: API_VideoReference[];
};

export function isAPI_Playlist(object: any): object is API_Playlist {
  return !!object && !!object.videos;
}
