import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  HStack,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { FaTv } from "react-icons/fa";
import { InternalLink } from "../../../../components/link";
import { VideoThumbnail } from "../../../../components/VideoThumbnail";
import { usePlaylist } from "../../../../data/api/playlist/usePlaylist";
import { useSubscriberCount } from "../../../../data/youtube/useSubscriberCount";

export const HGWMHero = () => {
  const { viewCount, subscriberCount } = useSubscriberCount();
  const { playlist } = usePlaylist();
  const amountOfPreviews = useBreakpointValue({
    base: 2,
    sm: 2,
    md: 3,
    lg: 4,
    default: 2,
  });
  return (
    <section>
      <Card
        w="full"
        p={{ base: 5, sm: 8 }}
        bg="linear-gradient(90deg, rgba(255,255,255,0.08309261204481788) 67%, rgb(255 255 255 / 8%) 100%);"
      >
        <VStack spacing={8} align="start">
          <VStack spacing={4} align="start" w="full">
            <VStack spacing={1} align="start">
              <Heading size={{ base: "lg", sm: "2xl" }}>
                How Games Were Made
              </Heading>
            </VStack>
            <Text maxW={"450px"} textAlign="start">
              "How Games Were Made" is a YouTube documentary series detailing
              the development of indie video games in a stylized way.
            </Text>
            <HStack
              spacing={{ base: 5, sm: 10 }}
              display={{ base: "none", sm: "flex" }}
            >
              <Stat size={{ base: "sm", sm: "md" }}>
                <StatNumber>{playlist?.videos.length}</StatNumber>
                <StatLabel>Videos</StatLabel>
              </Stat>
              <Stat size={{ base: "sm", sm: "md" }}>
                <StatNumber>{Math.floor(subscriberCount / 1000)}K+</StatNumber>
                <StatLabel>Subscribers</StatLabel>
              </Stat>
              <Stat size={{ base: "sm", sm: "md" }}>
                <StatNumber>{Math.floor(viewCount / 1000000)}M+</StatNumber>
                <StatLabel>Views</StatLabel>
              </Stat>
            </HStack>
            <Box>
              <Button
                as={InternalLink}
                to={"/how-games-were-made"}
                bg="pink.500"
                color="white"
                colorScheme={"pink"}
                size={{ base: "sm", sm: "md" }}
                leftIcon={<FaTv />}
              >
                Browse the full series
              </Button>
            </Box>
          </VStack>
          <Stack w="full" spacing={3}>
            <Heading size="sm">Recent uploads</Heading>

            <Box w="full">
              <HStack
                overflow={"visible"}
                spacing={{ base: 2, sm: 4 }}
                h="fit-content"
                alignItems={"stretch"}
              >
                {playlist?.videos.slice(0, amountOfPreviews).map((v) => (
                  <Flex flex={1} key={v.slug}>
                    <VideoThumbnail reference={v} />
                  </Flex>
                ))}
              </HStack>
            </Box>
          </Stack>
        </VStack>
      </Card>
    </section>
  );
};
