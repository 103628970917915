import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useParams,
} from "react-router-dom";
import { Layout } from "./layouts/MainLayout";
import { HomeScreen } from "./screens/Home";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};
const theme = extendTheme({
  config,
  fonts: {
    heading: `'Futura PT', sans-serif`,
    body: `'Futura PT', sans-serif`,
  },
});

export const App = () => (
  <HelmetProvider>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </HelmetProvider>
);

const PartnershipScreen = lazy(() => import("./screens/Partnership"));
const FAQScreen = lazy(() => import("./screens/FAQ"));
const ScriptScreen = lazy(() => import("./screens/Script"));
const ReadsScreen = lazy(() => import("./screens/Reads"));
const HowGamesWereMadeScreen = lazy(() => import("./screens/HowGamesWereMade"));
const ConfirmationScreen = lazy(() => import("./screens/ConfirmationScreen"));

const VideoToArticleRedirect = () => {
  const { postSlug } = useParams();
  return <Navigate replace to={`/read/${postSlug}`} />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <HomeScreen />
      </Layout>
    ),
  },
  {
    path: "/faq",
    element: (
      <Layout>
        <FAQScreen />
      </Layout>
    ),
  },
  {
    path: "/how-games-were-made",
    element: (
      <Layout>
        <HowGamesWereMadeScreen />
      </Layout>
    ),
  },
  {
    path: "/how-games-were-made/reads",
    element: (
      <Layout>
        <ReadsScreen />
      </Layout>
    ),
  },
  {
    path: "/partnerships",
    element: (
      <Layout>
        <PartnershipScreen />
      </Layout>
    ),
  },
  {
    path: "/partnerships/confirmation",
    element: (
      <Layout>
        <ConfirmationScreen />
      </Layout>
    ),
  },
  {
    path: "video/:postSlug",
    element: <VideoToArticleRedirect />,
  },
  {
    path: "article/:ytid",
    element: (
      <Layout>
        <ScriptScreen />
      </Layout>
    ),
  },
  {
    // support any user-friendly slug, prevents urls from hitting dead ends when video title changes
    path: "article/:ytid/*",
    element: (
      <Layout>
        <ScriptScreen />
      </Layout>
    ),
  },
  {
    // legacy routes without permalink key (ytid), resolution via slug is best effort
    path: "read/:postSlug",
    element: (
      <Layout>
        <ScriptScreen />
      </Layout>
    ),
  },
]);
