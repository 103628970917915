type EventParams = { [paramName: string]: string };

declare global {
  interface Window {
    gtag?: (event: "event", eventName: string, params: EventParams) => void;
  }
}

export function trackAnalyticsEvent(
  eventName: string,
  eventParams: EventParams
): void {
  window.gtag && window.gtag("event", eventName, eventParams);
}
