import {
  Box,
  Button,
  Center,
  Heading,
  IconButton,
  SlideFade,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { FaXmark } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useStorage";
import { trackAnalyticsEvent } from "../../utils/tracking/logAnalyticsEvent";
import { publishProductFeedback } from "../../utils/tracking/publishProductFeedback";
import { InternalLink } from "../link";

export const PartnershipsPopUp = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const [hasInteractedBefore, setHasInteractedBefore] = useLocalStorage(
    "tgg-partner-popup-interacted",
    false
  );

  const pathName = useLocation().pathname;
  const isPartnershipsPage = pathName.startsWith("/partnerships");
  const isHomePage = pathName === "/";

  useEffect(() => {
    if (isPartnershipsPage || isHomePage) {
      return;
    }
    setTimeout(() => {
      onOpen();
    }, 2000);
  }, [onOpen, isPartnershipsPage, isHomePage, hasInteractedBefore]);

  if (hasInteractedBefore) {
    return null;
  }

  const onDoClose = (positive: boolean) => {
    trackAnalyticsEvent("partnership_modal_interaction", {
      positive: `${positive}`,
    });
    setHasInteractedBefore(true);
    onClose();
  };

  const onStartPartnership = () => {
    publishProductFeedback(
      "A user has just positively interacted with the Partnerships pop-up."
    );
    onDoClose(true);
  };

  return (
    <Box pos="fixed" right={0} m={5} bottom={0} zIndex={"modal"}>
      <SlideFade in={isOpen}>
        <Stack
          bg="pink.500"
          p={4}
          pr={7}
          borderRadius="md"
          boxShadow={"2xl"}
          maxW={{ base: undefined, sm: "370px" }}
          spacing={4}
        >
          <Heading color="whit" fontSize={"2xl"} textAlign="center">
            Let's promote your game! 🚀
          </Heading>
          <Text textAlign={"center"} fontSize="lg">
            Partner with ThatGuyGlen to promote your game to more than 190K
            gaming enthusiasts.
          </Text>
          <Center>
            <Button
              bg="white"
              variant="solid"
              color="pink.500"
              onClick={onStartPartnership}
              colorScheme="grey"
            >
              <InternalLink to={"/partnerships"}>
                Let's work together!
              </InternalLink>
            </Button>
          </Center>
        </Stack>
        <IconButton
          onClick={() => onDoClose(false)}
          pos="absolute"
          size="xs"
          borderRadius="0"
          top={0}
          right={0}
          color="white"
          colorScheme={"white"}
          aria-label="Sluit nieuwsbrief widget"
          icon={<FaXmark />}
        />
      </SlideFade>
    </Box>
  );
};
