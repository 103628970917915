import { AspectRatio, Box, Heading, Image, VStack } from "@chakra-ui/react";
import { API_VideoReference } from "../../data/api/playlist/playlist";
import { glowOnHover } from "../../screens/Home";
import { createArticlePermalink } from "../../utils/routes";
import { InternalLink } from "../link";

interface VideoThumbnailProps {
  reference: API_VideoReference;
}

export const VideoThumbnail = ({ reference }: VideoThumbnailProps) => {
  const { title, thumbnailUrl, ytid } = reference;
  return (
    <InternalLink to={createArticlePermalink(ytid)} h="full">
      <Box borderRadius="lg" overflow={"hidden"} {...glowOnHover} h="full">
        <VStack spacing={1} h="full">
          <AspectRatio w="full" ratio={16 / 9}>
            <Image
              alt={`Video thumbnail for ${title}`}
              w="full"
              h="full"
              objectFit={"contain"}
              src={thumbnailUrl}
              borderTopRadius="lg"
              overflow={"hidden"}
              backgroundColor="gray.600"
            />
          </AspectRatio>
          <Box
            w="full"
            p={3}
            bgColor={"#2d374866"}
            borderBottomRadius="lg"
            border="1px solid"
            borderColor={"gray.600"}
            display="flex"
            flex={1}
          >
            <Heading fontSize={"lg"} fontWeight="medium">
              {title}
            </Heading>
          </Box>
        </VStack>
      </Box>
    </InternalLink>
  );
};
