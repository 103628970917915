import { Button, HStack, VisuallyHidden } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import {
  FaGamepad,
  FaHome,
  FaPatreon,
  FaQuestion,
  FaTv,
  FaYoutube,
} from "react-icons/fa";
import { useMatch } from "react-router-dom";
import { ExternalLink, InternalLink } from "../link";

export const DesktopNav = () => {
  const isPartnerShipsActive = !!useMatch("/partnerships");
  const isHomeActive = !!useMatch("/");
  const isFAQActive = !!useMatch("/faq");
  const isHGWMActive = !!useMatch("/how-games-were-made");
  return (
    <nav>
      <HStack
        spacing={0}
        alignContent="center"
        border="1px solid"
        borderColor={"gray.600"}
        borderBottomRadius={"lg"}
        display={["none", "none", "flex", "flex"]}
      >
        <SideNavInternalItem active={isHomeActive} icon={<FaHome />} href="/">
          Home
        </SideNavInternalItem>
        <SideNavInternalItem
          active={isPartnerShipsActive}
          icon={<FaGamepad />}
          href="/partnerships"
        >
          Partnerships
        </SideNavInternalItem>
        <SideNavInternalItem
          active={isHGWMActive}
          icon={<FaTv />}
          href="/how-games-were-made"
        >
          How Games Were Made
        </SideNavInternalItem>
        <SideNavInternalItem
          active={isFAQActive}
          icon={<FaQuestion />}
          href="/faq"
        >
          FAQ
        </SideNavInternalItem>
        <VisuallyHidden>
          <Button
            leftIcon={<FaPatreon />}
            as={ExternalLink}
            href="https://www.patreon.com/ThatGuyGlen"
          ></Button>

          <Button leftIcon={<FaYoutube />} as={ExternalLink}></Button>
        </VisuallyHidden>
      </HStack>
    </nav>
  );
};

type SideNavItemProps = PropsWithChildren<{
  icon: JSX.Element;
  href: string;
  active?: boolean;
}>;

const SideNavInternalItem = ({
  icon,
  children,
  href,
  active,
}: SideNavItemProps) => {
  return (
    <Button
      leftIcon={icon}
      variant="ghost"
      as={InternalLink}
      borderRadius={0}
      to={href}
      py={8}
      px={8}
      _hover={{ bgColor: "#2d374866" }}
      _active={{ bgColor: "#2d374866" }}
      isActive={active}
    >
      {children}
    </Button>
  );
};
