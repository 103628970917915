import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Heading,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { glowOnHover } from "../..";
import { InternalLink } from "../../../../components/link";
import { useSubscriberCount } from "../../../../data/youtube/useSubscriberCount";

export const HeroBanner = () => {
  return (
    <LinkBox as="section" {...glowOnHover}>
      <Box
        borderWidth="1px"
        borderRadius={"lg"}
        backdropBlur="2px"
        borderColor={"whiteAlpha.700"}
        bg={"whiteAlpha.200"}
        pt={5}
        pb={2}
        px={{ base: 3, md: 5 }}
      >
        <Container py={{ base: "4", md: "3.5" }} maxW="container.md">
          <Stack
            spacing={{ base: "4", sm: "6", md: "8" }}
            justify="space-between"
            align={"center"}
          >
            <Stack align="center" textAlign={"center"}>
              <Heading
                as="h1"
                fontWeight="medium"
                fontSize={{ base: "3xl", md: "4xl" }}
                textAlign="center"
              >
                Get your game in front of <SubscriberCountBadge /> indie game
                enthusiasts! 🎮
              </Heading>
            </Stack>
            <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} align="center">
              Marketing indie games is notoriously difficult. Not only is it a
              daunting task, but it takes up precious development time. A
              partnership with{" "}
              <Link
                colorScheme={"pink"}
                fontWeight="bold"
                href="https://www.youtube.com/@ThatGuyGlen"
              >
                ThatGuyGlen
              </Link>{" "}
              will help you get your game in front of the right audience so you
              can focus more on creating an awesome indie title. Submit a
              partnership application and we'll be in touch!
            </Text>
            <LinkOverlay as={InternalLink} to="/partnerships">
              <ButtonGroup isAttached size={{ base: "md", lg: "lg" }}>
                <Button colorScheme={"pink"} bg="pink.500" color="white">
                  Let's collab!
                </Button>
              </ButtonGroup>
            </LinkOverlay>
          </Stack>
        </Container>
      </Box>
    </LinkBox>
  );
};

const SubscriberCountBadge = () => {
  const { subscriberCount } = useSubscriberCount();

  return <em>{numberWithCommas(subscriberCount)}</em>;
};

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
