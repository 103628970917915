import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Card,
  Heading,
  LinkBox,
  LinkOverlay,
  VStack,
} from "@chakra-ui/react";
import { glowOnHover } from "../..";

type PlatformCardProps = {
  name: string;
  bg: BoxProps["bg"];
  icon: React.ReactElement;
  url: string;
  ctaText: string;
};

export const PlatformCard = ({
  name,
  icon,
  url,
  bg,
  ctaText,
}: PlatformCardProps) => {
  return (
    <Card
      borderColor={bg}
      bg={"whiteAlpha.200"}
      p={{ base: 2, sm: 4 }}
      w="full"
      {...glowOnHover}
    >
      <LinkBox>
        <AspectRatio ratio={4 / 4} maxW="full">
          <VStack spacing={5}>
            <Heading size={{ base: "md", sm: "lg", md: "lg" }}>{name}</Heading>
            <Box fontSize="5xl">{icon}</Box>
            <LinkOverlay href={url} target="_blank">
              <Button
                bg={bg}
                colorScheme="pink"
                color="white"
                size={{ base: "sm", sm: "md" }}
              >
                {ctaText}
              </Button>
            </LinkOverlay>
          </VStack>
        </AspectRatio>
      </LinkBox>
    </Card>
  );
};
