import {
  Link as Chakralink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import React from "react";
import { Link as RRLink, LinkProps as RRLinkProps } from "react-router-dom";

type InternalLinkProps = Omit<ChakraLinkProps & RRLinkProps, "as">;

export const InternalLink = React.forwardRef(
  (props: InternalLinkProps, ref) => {
    // @ts-ignore
    return <Chakralink {...props} ref={ref} as={RRLink} />;
  }
);

type ExternalLinkProps = ChakraLinkProps;

export const ExternalLink = React.forwardRef(
  (props: ExternalLinkProps, ref) => {
    return (
      <Chakralink ref={ref} target={"_blank"} {...props} as={Chakralink} />
    );
  }
);
