import { trackAnalyticsEvent } from "./logAnalyticsEvent";

const where =
  "aHR0cHM6Ly9kaXNjb3JkLmNvbS9hcGkvd2ViaG9va3MvMTE5MDU5NzY3MjAxNzU5MjQyMC9OY2VXSzJ2WWVTcHRLQ19UbHpISVRMQ2tFOHVka0tfMHBQMlVxYXp3Q1F5R0RlazdqMkZJY0dfeDJtX0duUnVsX3hwTw==";

export function publishProductFeedback(description: string): Promise<Response> {
  // docu https://birdie0.github.io/discord-webhooks-guide/discord_webhook.html
  const message = {
    embeds: [
      {
        description,
        color: 13975436,
      },
    ],
  };
  trackAnalyticsEvent("product_feedback", { description });
  return fetch(atob(where), {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(message),
    keepalive: true,
  });
}
