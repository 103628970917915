import { useMemo, useState } from "react";

interface useSubscriberCountReturn {
  isLoading: boolean;
  subscriberCount: number;
  viewCount: number;
  videoCount: number;
}

interface YTDataSourceResponse {
  items: [
    {
      kind: "youtube#channel";
      id: string;
      statistics: {
        /**
         *  number as string
         */
        viewCount: string;
        /**
         *  number as string
         */
        subscriberCount: string;
        /**
         *  number as string
         */
        videoCount: string;
      };
    }
  ];
}

export function useSubscriberCount(): useSubscriberCountReturn {
  const [data, setData] = useState<{
    isLoading: boolean;
    subscriberCount: number;
    viewCount: number;
    videoCount: number;
  }>({
    isLoading: true,
    videoCount: 38,
    viewCount: 25668862,
    subscriberCount: 187000,
  });
  useMemo(() => {
    fetch(`.netlify/functions/yt-data-source`)
      .then((response) => response.json())
      .then((ytData: YTDataSourceResponse) => {
        setData({
          isLoading: false,
          videoCount: +ytData.items[0]?.statistics.videoCount || 38,
          viewCount: +ytData.items[0]?.statistics.viewCount || 25668862,
          subscriberCount:
            +ytData.items[0]?.statistics.subscriberCount || 186000,
        });
      })
      .catch(() =>
        setData({
          videoCount: 38,
          viewCount: 25668862,
          subscriberCount: 186000,
          isLoading: false,
        })
      );
  }, []);

  return data;
}
