import {
  Button,
  Center,
  chakra,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  DrawerProps,
  Heading,
  Image,
  Link,
  StackDivider,
  VStack,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import {
  FaGamepad,
  FaHeart,
  FaHome,
  FaPatreon,
  FaQuestion,
  FaTv,
  FaYoutube,
} from "react-icons/fa";
import { useMatch } from "react-router-dom";
import { InternalLink } from "../link";

type SideNavProps = Pick<DrawerProps, "isOpen" | "onClose" | "finalFocusRef">;
export const SideNav = (props: SideNavProps) => {
  const isPartnerShipsActive = !!useMatch("/partnerships");
  const isHomeActive = !!useMatch("/");
  const isFAQActive = !!useMatch("/faq");
  const isHGWMActive = !!useMatch("/how-games-were-made");
  return (
    <Drawer {...props} placement="right">
      <DrawerOverlay />
      <DrawerContent background="linear-gradient(0deg, rgba(15,0,61,1) 0%, #12161e 85%);">
        <DrawerBody p={0}>
          <nav>
            <VStack divider={<StackDivider />} spacing={0}>
              <Center p={5} w="full">
                <Image
                  w="200px"
                  h="200px"
                  border="3px solid white"
                  borderRadius={"full"}
                  src="/android-chrome-512x512.png"
                />
              </Center>
              <SideNavInternalItem
                icon={<FaHome />}
                active={isHomeActive}
                href="/"
              >
                Home
              </SideNavInternalItem>
              <SideNavInternalItem
                icon={<FaGamepad />}
                href="/partnerships"
                active={isPartnerShipsActive}
              >
                Partnerships
              </SideNavInternalItem>
              <SideNavInternalItem
                active={isHGWMActive}
                icon={<FaTv />}
                href="/how-games-were-made"
              >
                How Games Were Made
              </SideNavInternalItem>
              <SideNavItem
                icon={<FaPatreon />}
                href="https://www.patreon.com/ThatGuyGlen"
              >
                Patreon
              </SideNavItem>
              <SideNavItem
                icon={<FaYoutube />}
                href="https://www.youtube.com/@ThatGuyGlen"
              >
                YouTube
              </SideNavItem>
              <SideNavInternalItem
                active={isFAQActive}
                icon={<FaQuestion />}
                href="/faq"
              >
                FAQ
              </SideNavInternalItem>
            </VStack>
          </nav>
        </DrawerBody>
        <DrawerFooter>
          <Heading
            mt={10}
            as="h3"
            size="xs"
            w="full"
            fontWeight={"normal"}
            fontStyle="italic"
            textAlign="center"
          >
            <chakra.span color="pink.400" display={"inline-flex"}>
              <FaHeart />
            </chakra.span>{" "}
            by{" "}
            <Link
              color="pink.400"
              href="https://www.linkedin.com/in/samgielis/"
            >
              Sam Gielis
            </Link>
          </Heading>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

type SideNavItemProps = PropsWithChildren<{
  icon: JSX.Element;
  href: string;
  active?: boolean;
}>;
const SideNavItem = ({ icon, children, href, active }: SideNavItemProps) => {
  return (
    <Button
      w="full"
      leftIcon={icon}
      variant="ghost"
      as={"a"}
      target="_blank"
      href={href}
      borderRadius={0}
      py={8}
      _hover={{ bgColor: "#2d374866" }}
      _active={{ bgColor: "#2d374866" }}
      isActive={active}
    >
      {children}
    </Button>
  );
};
const SideNavInternalItem = ({
  icon,
  children,
  href,
  active,
}: SideNavItemProps) => {
  return (
    <Button
      w="full"
      leftIcon={icon}
      variant="ghost"
      as={InternalLink}
      to={href}
      borderRadius={0}
      py={8}
      _hover={{ bgColor: "#2d374866" }}
      _active={{ bgColor: "#2d374866" }}
      isActive={active}
    >
      {children}
    </Button>
  );
};
