import { Container, Stack } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { HeroBanner } from "./components/HeroBanner";
import { HGWMHero } from "./components/HGWMHero";
import { PlatformCards } from "./components/PlatformCards";

export const glowOnHover = {
  _hover: {
    transform: `scale(1.05)`,
    opacity: 1,
    filter: `drop-shadow(5px 4px 2px #4444dd)`,
  },
  opacity: 0.95,
  transition: ".3s ease-in-out all",
};
export const HomeScreen = () => {
  return (
    <>
      <Helmet>
        <title>{`ThatGuyGlen`}</title>
        <meta
          name="description"
          content={
            "I make videos that tell the story of how games were made. Follow me on YouTube."
          }
        />
      </Helmet>
      <Container maxW="container.lg" p={0}>
        <Stack spacing={{ base: 4, md: 8 }}>
          <HeroBanner />
          <PlatformCards />
          <HGWMHero />
        </Stack>
      </Container>
    </>
  );
};
