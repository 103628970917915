import { useEffect, useState } from "react";
import { API_Playlist, isAPI_Playlist } from "./playlist";

type usePlaylistReturn = {
  isLoading: boolean;
  playlist: API_Playlist | undefined;
};

export function usePlaylist(): usePlaylistReturn {
  const [playlist, setPlaylist] = useState<API_Playlist | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(`/.netlify/functions/playlist`)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (isAPI_Playlist(data)) {
          setPlaylist(data);
        } else {
          setPlaylist(undefined);
        }
      });
  }, []);

  return {
    isLoading,
    playlist,
  };
}
