import {
  Box,
  Container,
  HStack,
  IconButton,
  Image,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { PropsWithChildren, Suspense } from "react";
import { FaBars } from "react-icons/fa";
import { DesktopNav } from "../../components/desktopnav/DesktopNav";
import { InternalLink } from "../../components/link";
import { PartnershipsPopUp } from "../../components/PartnershipsPopUp";
import { SideNav } from "../../components/sidenav/SideNav";
import { glowOnHover } from "../../screens/Home";
import { PostLoadingPlaceholder } from "../../screens/Post/PostLoadingPlaceholder";
import ScrollToTop from "../../ScrollToTop";

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);

  return (
    <>
      <PartnershipsPopUp />
      <ScrollToTop />
      <VStack spacing={0}>
        <Box w="1px" h="0px" id="scrollanchor" />
        <Box>
          <HStack
            w="full"
            p={4}
            zIndex={1}
            position="fixed"
            right={0}
            top={0}
            alignContent="center"
            background={[
              "linear-gradient(0deg, rgba(15,0,61,0) 0%, #12161e 0%);",
              "linear-gradient(0deg, rgba(15,0,61,0) 0%, #12161e 0%);",
              "transparent",
            ]}
          >
            <InternalLink to="/">
              <Image
                alt="ThatGuyGlen logo"
                display={["block", "block", "none"]}
                _hover={{
                  transform: `scale(1.05)`,
                  opacity: 1,
                  filter: `drop-shadow(5px 4px 2px #4444dd)`,
                }}
                opacity={0.95}
                transition=".3s ease-in-out all"
                maxH={10}
                src="/logo-long.webp"
              />
            </InternalLink>
            <Spacer />
            <IconButton
              {...glowOnHover}
              isRound={true}
              variant="solid"
              colorScheme="whiteAlpha"
              aria-label="Open menu"
              fontSize="20px"
              icon={<FaBars />}
              ref={btnRef}
              size="lg"
              onClick={onOpen}
              color="white"
              border="2px solid"
              borderColor={"gray.600"}
              backdropFilter="blur(2px)"
            />
          </HStack>
        </Box>
        <Container maxW="container.xl" my={20} px={{ base: 3, sm: 5 }}>
          <VStack spacing={[4, 4, 14]}>
            <header>
              <VStack spacing={3} display={["none", "none", "flex"]}>
                <InternalLink to="/">
                  <Image
                    alt={"ThatGuyGlen G logo"}
                    _hover={{
                      transform: `scale(1.05)`,
                      opacity: 1,
                      filter: `drop-shadow(5px 4px 2px #4444dd)`,
                    }}
                    opacity={0.95}
                    transition=".3s ease-in-out all"
                    maxW="90vw"
                    maxH="120px"
                    src="/logo-long.webp"
                  />
                </InternalLink>
                <DesktopNav />
              </VStack>
            </header>
            <Suspense fallback={<PostLoadingPlaceholder />}>
              {children}
            </Suspense>
          </VStack>
        </Container>
      </VStack>
      <SideNav isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} />
    </>
  );
};
