import { SimpleGrid } from "@chakra-ui/react";
import { FaDiscord, FaPatreon, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { PlatformCard } from "./PlatformCard";

export const PlatformCards = () => {
  const gridSpacing = { base: 4, md: 6, lg: 8 };
  return (
    <SimpleGrid
      w="full"
      columns={{ base: 2, md: 4 }}
      columnGap={gridSpacing}
      rowGap={gridSpacing}
    >
      <PlatformCard
        name={"YouTube"}
        bg="red"
        icon={<FaYoutube />}
        url={"https://www.youtube.com/@ThatGuyGlen"}
        ctaText={"Watch on YouTube"}
      />
      <PlatformCard
        name={"Patreon"}
        bg="#f96854"
        icon={<FaPatreon />}
        url={"https://www.patreon.com/ThatGuyGlen"}
        ctaText={"Become a Patron"}
      />
      <PlatformCard
        name={"Discord"}
        bg="#5865F2"
        icon={<FaDiscord />}
        url={"https://www.patreon.com/ThatGuyGlen"}
        ctaText={"Join the server"}
      />
      <PlatformCard
        name={"X"}
        bg="#000"
        icon={<FaXTwitter />}
        url={"https://twitter.com/GlenCallaert"}
        ctaText={"Follow me on X"}
      />
    </SimpleGrid>
  );
};
